<template>
  <div class="container">
    <div class="page-sub-box ewm">
      <div class="pt-14 space-y-8">
        <table class="table_form line-bin">
          <colgroup>
            <col style="width: 130px" />
            <col style="width: auto" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label for="label5">소속</label>
              </th>
              <td>{{ this.initData.deptNmPath }}</td>
            </tr>
            <tr>
              <th scope="row">
                <label for="label5">상담원</label>
              </th>
              <td>{{ this.initData.agtNm }} [{{ this.initData.agtId }}]</td>
            </tr>
            <tr>
              <th scope="row">
                <label for="label5">휴가구분 <span class="icon_require">필수항목</span></label>
              </th>
              <td>
                <DxDropDownBox
                  v-model="vacationList.selectedRowKeys"
                  :opened="vacationList.isGridBoxOpened"
                  :display-expr="gridBoxDisplayExpr"
                  :data-source="vacationList.dataSource"
                  value-expr="id"
                  placeholder="휴가구분 선택"
                  :styling-mode="config.stylingMode"
                  class="mr-4"
                  width="300"
                  @opened="onDropdownOpened"
                  :drop-down-options="{
                    width: 750,
                  }"
                >
                  <DxValidator validation-group="validationSave">
                    <DxRequiredRule message="휴가구분은 필수입니다." />
                  </DxValidator>
                  <template #content="{}">
                    <DxDataGrid
                      :data-source="vacationList.dataSource"
                      :selected-row-keys="vacationList.selectedRowsData"
                      @selection-changed="onAddVacationType($event)"
                      ref="dropdownGrid"
                      :show-column-headers="true"
                      :show-borders="false"
                      :column-auto-width="true"
                      :show-row-lines="true"
                      :show-column-lines="false"
                      height="100%"
                    >
                      <DxSelection mode="single" />
                      <DxSorting mode="multiple" />

                      <DxColumn
                        caption="휴가구분"
                        data-field="vacationTypePtCd"
                        alignment="center"
                        :calculate-sort-value="data => calculateSortValue(data, 'vacationTypePtCd')"
                        :sort-index="0"
                        sort-order="asc"
                      >
                        <DxLookup :data-source="codes.vacationTypePtCd.dataSource" value-expr="codeId" display-expr="codeNm" />
                      </DxColumn>
                      <DxColumn
                        caption="구분상세"
                        data-field="vacationTypeCd"
                        alignment="center"
                        :calculate-sort-value="data => calculateSortValue(data, 'vacationTypeCd')"
                        :sort-index="1"
                        sort-order="asc"
                      >
                        <DxLookup :data-source="codes.vacationTypeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
                      </DxColumn>
                      <DxColumn
                        caption="시간옵션"
                        data-field="vacationTimeTypeCd"
                        alignment="center"
                        :calculate-sort-value="data => calculateSortValue(data, 'vacationTimeTypeCd')"
                      >
                        <DxLookup :data-source="codes.vacationTimeTypeCd.dataSource" value-expr="value" display-expr="label" />
                      </DxColumn>
                      <DxColumn caption="시작시간" data-field="vacationStartTime" alignment="center" width="100" />
                      <DxColumn caption="종료시간" data-field="vacationEndTime" alignment="center" width="100" />
                      <DxColumn caption="일수" data-field="vacationDayoff" alignment="center" width="60" />
                    </DxDataGrid>
                  </template>
                </DxDropDownBox>
              </td>
            </tr>
            <!-- FIXME : EWM 공통코드 하드코딩 -->
            <tr v-if="this.formData.vacationTimeTypeCd !== 1234">
              <!-- 종일 -->
              <th scope="row">
                <label for="label5">휴가일자 <span class="icon_require">필수항목</span></label>
              </th>
              <td class="flex">
                <div>
                  <DxDateBox
                    styling-mode="outlined"
                    :width="120"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    display-format="yyyy-MM-dd"
                    :disabledDates="config.disabledDates"
                    v-model="formData.vacationStartYmd"
                    @value-changed="chooseVacationDays"
                  >
                    <DxValidator validation-group="validationSave">
                      <DxRequiredRule message="휴가일자는 필수입니다." />
                    </DxValidator>
                  </DxDateBox>
                </div>
                <div>
                  <span class="mx-4"> ~ </span>
                  <DxDateBox
                    styling-mode="outlined"
                    :width="120"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    display-format="yyyy-MM-dd"
                    :min="formData.vacationStartYmd"
                    :disabledDates="config.disabledDates"
                    v-model="formData.vacationEndYmd"
                    @value-changed="chooseVacationDays"
                  >
                    <DxValidator validation-group="validationSave">
                      <DxRequiredRule message="휴가일자는 필수입니다." />
                    </DxValidator>
                  </DxDateBox>
                </div>
                <div class="mt-2 ml-4">
                  신청일자 [<span class="text-red-500"> {{ this.formData.vacationDayoffCnt }} </span>]
                </div>
              </td>
            </tr>
            <tr v-else>
              <th scope="row">
                <label for="label5">휴가일자 <span class="icon_require">필수항목</span></label>
              </th>
              <td class="flex">
                <div>
                  <DxDateBox
                    styling-mode="outlined"
                    :width="120"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    display-format="yyyy-MM-dd"
                    :disabledDates="config.disabledDates"
                    v-model="formData.vacationStartYmd"
                    @value-changed="chooseVacationDays"
                  >
                    <DxValidator validation-group="validationSave">
                      <DxRequiredRule message="휴가일자는 필수입니다." />
                    </DxValidator>
                  </DxDateBox>
                </div>
                <div class="mt-2 ml-4">
                  신청일자 [<span class="text-red-500"> {{ this.formData.vacationDayoffCnt }} </span>]
                </div>
              </td>
            </tr>
            <!-- FIXME : EWM 공통코드 하드코딩 -->
            <tr v-if="this.formData.vacationTimeTypeCd === 1234">
              <th scope="row">
                <label for="label5">휴가시간 <span class="icon_require">필수항목</span></label>
              </th>
              <td class="w-72 flex justify-center tracking-wider">
                {{ formatDate(this.formData.vacationStartTime, 'HHmm', 'HH:mm') }}
                ~
                {{ formatDate(this.formData.vacationEndTime, 'HHmm', 'HH:mm') }}
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="label5">연차일수</label>
              </th>
              <td class="flex space-x-6 w-72">
                <div class="w-1/2 flex justify-center">
                  잔여연차: <span class="text-red-500 ml-2">{{ this.initData.leastVacation }}</span>
                </div>
                <div class="w-1/2 flex justify-center">
                  차감연차: <span class="text-red-500 ml-2">{{ this.formData.vacationDayoff }}</span>
                </div>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="label5">휴가사유</label>
              </th>
              <td>
                <DxTextArea
                  :styling-mode="config.stylingMode"
                  class="mr-4"
                  :show-clear-button="true"
                  width="300"
                  :height="100"
                  v-model="formData.approvalRequestReason"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="label5">첨부파일</label>
              </th>
              <td>
                <DxTextBox
                  :styling-mode="config.stylingMode"
                  v-model="formData.originalFileName"
                  :show-clear-button="true"
                  id="dropzone-external"
                  width="300"
                  height="30"
                />
                <DxFileUploader
                  id="file-uploader"
                  upload-mode="useButtons"
                  dialog-trigger="#dropzone-external"
                  :visible="false"
                  @value-changed="uploadFile"
                />
              </td>
            </tr>
            <tr>
              <th scope="row">
                <label for="label5">결재정보</label>
              </th>
              <td>
                <div class="space-y-6">
                  <div class="space-x-6 flex items-center">
                    <span class="w-10">구분</span>
                    <span class="w-40 flex justify-center">결재자명</span>
                    <span class="w-20 flex justify-center">승인여부</span>
                  </div>
                  <div class="space-x-6 flex items-center">
                    <span class="w-10">1차</span>
                    <span class="w-40">
                      <DxSelectBox
                        placeholder="1차 결재자"
                        :data-source="approval.firstAgt"
                        display-expr="agtNm"
                        value-expr="agtId"
                        v-model="formData.primaryApproverId"
                        :styling-mode="config.stylingMode"
                      >
                        <DxValidator validation-group="validationSave">
                          <DxRequiredRule message="결재자는 필수입니다." />
                        </DxValidator>
                      </DxSelectBox>
                    </span>
                    <span class="w-20 flex justify-center">
                      {{ formData.primaryApprovalStatusNm }}
                    </span>
                  </div>
                  <div class="space-x-6 flex items-center">
                    <span class="w-10">2차</span>
                    <span class="w-40">
                      <DxSelectBox
                        placeholder="2차 결재자"
                        :data-source="approval.secondAgt"
                        display-expr="agtNm"
                        value-expr="agtId"
                        v-model="formData.secondaryApproverId"
                        :styling-mode="config.stylingMode"
                      >
                      </DxSelectBox>
                    </span>
                    <span class="w-20 flex justify-center">
                      {{ formData.secondaryApprovalStatusNm }}
                    </span>
                  </div>
                  <div class="space-x-6 flex items-center">
                    <span class="w-10">3차</span>
                    <span class="w-40">
                      <DxSelectBox
                        placeholder="3차 결재자"
                        :data-source="approval.thirdtAgt"
                        display-expr="agtNm"
                        value-expr="agtId"
                        v-model="formData.tertiaryApproverId"
                        :styling-mode="config.stylingMode"
                      >
                      </DxSelectBox>
                    </span>
                    <span class="w-20 flex justify-center">
                      {{ formData.tertiaryApprovalStatusNm }}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <section class="terms bottom-btn-box">
      <div class="bottom-btn-wrap">
        <DxButton
          text="결재요청"
          class="default filled txt_S medium"
          :width="90"
          :height="40"
          @click="onSaveFormData"
          :visible="!config.updateYn"
        />
        <DxButton
          text="재기안"
          class="default filled txt_S medium"
          :width="90"
          :height="40"
          @click="onReSubmissionFormData"
          :visible="!config.reSubmission"
        />
        <DxButton text="취	소" class="btn_XS white filled txt_S medium" :width="90" :height="40" @click="onCancelFormData" />
      </div>
    </section>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
  import { DxFileUploader } from 'devextreme-vue/file-uploader';
  import { DxColumn, DxDataGrid, DxLookup, DxSelection, DxSorting } from 'devextreme-vue/data-grid';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import { formatDate, isSuccess, setSortingObjArr } from '@/plugins/common-lib';
  // import Holidays from 'date-holidays';

  export default {
    components: {
      DxButton,
      DxDateBox,
      DxTextBox,
      DxTextArea,
      DxFileUploader,
      DxSelectBox,
      DxDropDownBox,
      DxDataGrid,
      DxColumn,
      DxLookup,
      DxSelection,
      DxSorting,
      DxRequiredRule,
      DxValidator,
    },
    watch: {
      'vacationList.selectedRowsData': {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.vacationList.selectedRowKeys = this.vacationList.selectedRowsData.map(a => a.id);
          }
          if (!filterData) {
            this.$refs.dropdownGrid.instance.deselectAll();
          }
        },
        deep: true,
      },
      'vacationList.selectedRowKeys': {
        handler(val) {
          if (!val) {
            this.$refs.dropdownGrid.instance.deselectAll();
          }
        },
        deep: true,
      },
    },
    data() {
      return {
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled
          updateYn: false,
          reSubmission: false,
          disabledDates: [],
          holidayDates: [],
          myVacation: null,
        },
        codes: {
          vacationTypePtCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          vacationTypeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          vacationTimeTypeCd: {
            dataSource: [],
          },
          approvalStatus: [],
          dept: {
            dataSource: [],
          },
        },
        formData: {
          attachFileGroupId: null,
          originalFileName: null,
          vacationStartYmd: null,
          vacationEndYmd: null,
          vacationStartTime: null,
          vacationEndTime: null,
          vacationTypePtCd: null,
          vacationTypeCd: null,
          vacationDayoffCnt: 0,
          vacationDayoff: 0,
        },
        vacationList: {
          isGridBoxOpened: false,
          dataSource: [],
          selectedRowsData: [],
          selectedRowKeys: [],
        },
        initData: {},
        approval: {
          firstAgt: [],
          secondAgt: [],
          thirdtAgt: [],
        },
      };
    },
    computed: {},
    methods: {
      formatDate,
      /** @description : 드롭다운 메뉴 오픈 이벤트  */
      onDropdownOpened() {
        this.vacationList.isGridBoxOpened = true;
      },
      /** @description : 드롭다운 메뉴 디스플레이 */
      gridBoxDisplayExpr(item) {
        let displayString = this.codes.vacationTypeCd.dataSource.find(v => v.codeId === item.vacationTypeCd).codeNm;
        return displayString + '/ 차감일수: ' + item.vacationDayoff;
      },
      /** @description : 소트설정 */
      calculateSortValue(data, clsCd) {
        if (clsCd === 'vacationTypePtCd') {
          return this.codes.vacationTypePtCd.dataSource.find(e => data.vacationTypePtCd === e.codeId).codeNm;
        } else if (clsCd === 'vacationTypeCd') {
          return this.codes.vacationTypeCd.dataSource.find(e => data.vacationTypeCd === e.codeId).codeNm;
        } else if (clsCd === 'vacationTimeTypeCd') {
          return this.$_enums.ewm.hrVacationTimeType.values.find(e => data.vacationTimeTypeCd === e.value).label;
        }
      },
      async vacationTypeList() {
        const payload = {
          actionname: 'EWM_HR_VACATION_TYPE',
          loading: false,
        };

        const res = await this.CALL_EWM_API(payload);

        console.log('res', res);

        if (isSuccess(res)) {
          let data = res.data.data.filter(d => d.viewFl === 'Y' || d.id === this.formData.vacationTypeId);
          data.forEach(r => {
            const deptMultiCds = r.deptMultiCd.split('|');
            const filteredDeptMultiCds = deptMultiCds.filter(cd => {
              return cd === '전체' || this.initData.depts.includes(cd);
            });

            if (filteredDeptMultiCds.length > 0) {
              r.vacationTypePtCd = this.codes.vacationTypeCd.dataSource.find(v => v.codeId === r.vacationTypeCd).parentId;
              this.vacationList.dataSource.push(r);
            }

            if (this.config.updateYn && this.formData.vacationTypeId) {
              this.vacationList.selectedRowsData = this.vacationList.dataSource.filter(v => v.id === this.formData.vacationTypeId);
            }
          });
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      async vacationLineList() {
        const payload = {
          actionname: 'EWM_PERSONAL_VACATION_LINE',
          data: {
            approvalType: this.$_enums.ewm.approvalType.VACATION.value,
          },
          loading: false,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.approval.firstAgt = res.data.data[0].approvalLine1;
          this.approval.secondAgt = res.data.data[0].approvalLine2;
          this.approval.thirdtAgt = res.data.data[0].approvalLine3;
        } else {
          this.$_Toast(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      getholidays() {
        let holidayList = this.getPulicHolidays();
        holidayList.map(d => {
          this.config.disabledDates.push(new Date(d.date));
          this.config.holidayDates.push(new Date(d.date));
        });

        const currentDate = new Date();
        const startDate = new Date(currentDate.getFullYear(), 0, 1);
        const endDate = new Date(currentDate.getFullYear(), 11, 31);

        for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
          if (date.getDay() === 0 || date.getDay() === 6) {
            this.config.disabledDates.push(new Date(date));
            this.config.holidayDates.push(new Date(date));
          }

          if (date < currentDate) {
            this.config.disabledDates.push(new Date(date));
          }
        }
      },
      chooseVacationDays() {
        if (!this.formData.vacationTypeId) {
          return;
        } else {
          // FIXME : EWM 공통코드 하드코딩
          if (this.formData.vacationTimeTypeCd === 1234) {
            this.formData.vacationEndYmd = this.formData.vacationStartYmd;
            this.formData.vacationDayoffCnt = 1;
            this.formData.vacationDayoff = this.formData.multiVacationDayoff;
            this.initData.leastVacation = this.config.myVacation - this.formData.vacationDayoff;
          } else {
            if (this.formData.vacationStartYmd && this.formData.vacationEndYmd) {
              const startDate = new Date(this.formData.vacationStartYmd);
              const endDate = new Date(this.formData.vacationEndYmd);
              const timeDifference = endDate.getTime() - startDate.getTime();
              const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

              const holidaysBetweenDates = this.config.holidayDates.filter(holiday => {
                return holiday >= startDate && holiday <= endDate;
              });

              let result = daysDifference - holidaysBetweenDates.length + 1;

              this.formData.vacationDayoffCnt = result;
              this.formData.vacationDayoff = this.formData.multiVacationDayoff * result;
              this.initData.leastVacation = this.config.myVacation - this.formData.vacationDayoff;
            }
          }
        }
        if (this.initData.leastVacation < 0) {
          validationEngine.resetGroup('validationSave');
          this.formData.vacationDayoffCnt = 0;
          this.formData.vacationDayoff = 0;
          this.initData.leastVacation = this.config.myVacation;
          this.$_Toast(`잔여연차를 확인해주세요.`);
        }
      },
      onAddVacationType(e) {
        this.vacationList.isGridBoxOpened = false;
        this.vacationList.selectedRowsData = e.selectedRowKeys;

        this.formData.vacationTypeId = e.selectedRowKeys[0].id;
        this.formData.vacationTypeCd = e.selectedRowKeys[0].vacationTypeCd;
        this.formData.vacationTimeTypeCd = e.selectedRowKeys[0].vacationTimeTypeCd;
        this.formData.vacationStartTime = e.selectedRowKeys[0].vacationStartTime;
        this.formData.vacationEndTime = e.selectedRowKeys[0].vacationEndTime;
        this.formData.multiVacationDayoff = e.selectedRowKeys[0].vacationDayoff;

        this.formData.vacationStartYmd = null;
        this.formData.vacationEndYmd = null;
        this.formData.vacationDayoffCnt = 0;
        this.formData.vacationDayoff = 0;
        this.initData.leastVacation = this.config.myVacation;
      },
      async uploadFile(e) {
        const res = await this.espApi.fileUpload(e.value);
        if (isSuccess(res)) {
          console.log('res', res);
          this.formData.attachFileGroupId = res.data.data[0].fileGroupId;
          this.formData.originalFileName = res.data.data[0].originalFileName;
        }
      },
      /** @description : 저장 메서드 */
      async onSaveFormData() {
        if (!validationEngine.validateGroup('validationSave').isValid) {
          return;
        }

        if (
          !(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>휴가신청</h2>`,
          }))
        ) {
          return;
        }

        let approvalData = {
          approvalType: this.$_enums.ewm.approvalType.VACATION.value,
          requestId: this.initData.agtId,
          requestInfo: '휴가일자: ' + this.formData.vacationStartYmd + ' ~ ' + this.formData.vacationEndYmd,
          requestReason: this.formData.approvalRequestReason,
          fileGroupId: this.formData.attachFileGroupId,
          primaryApproverId: this.formData.primaryApproverId,
          secondaryApproverId: this.formData.secondaryApproverId,
          tertiaryApproverId: this.formData.tertiaryApproverId,
        };

        const payload1 = {
          actionname: 'EWM_APPROVAL_REQUEST_INSERT',
          data: approvalData,
          useErrorPopup: true,
        };

        const res1 = await this.CALL_EWM_API(payload1);

        // FIXME : EWM 공통코드 하드코딩
        if (isSuccess(res1)) {
          let vacationUseData = {
            agtId: this.initData.agtId,
            vacationTypeId: this.formData.vacationTypeId,
            vacationTimeTypeCd: this.formData.vacationTimeTypeCd,
            vacationStartYmd: this.formData.vacationStartYmd,
            vacationEndYmd: this.formData.vacationTimeTypeCd === 1234 ? this.formData.vacationStartYmd : this.formData.vacationEndYmd,
            vacationStartTime: this.formData.vacationTimeTypeCd === 1234 ? this.formData.vacationStartTime : null,
            vacationEndTime: this.formData.vacationTimeTypeCd === 1234 ? this.formData.vacationEndTime : null,
            vacationDayoff: this.formData.vacationDayoff,
            vacationDayoffCnt: this.formData.vacationDayoffCnt,
            approvalId: res1.data.data[0],
            description: this.formData.approvalRequestReason,
          };

          const payload2 = {
            actionname: 'EWM_PERSONAL_VACATION_SAVE',
            data: vacationUseData,
            useErrorPopup: true,
          };

          const res2 = await this.CALL_EWM_API(payload2);

          if (isSuccess(res2)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            this.onCancelFormData();
          }
        }
      },
      async onReSubmissionFormData() {
        if (!validationEngine.validateGroup('validationSave').isValid) {
          return;
        }

        if (
          !(await this.$_Confirm(this.$_msgContents('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>휴가신청</h2>`,
          }))
        ) {
          return;
        }
        const payload = {
          actionname: 'EWM_APPROVAL_PROCESSING_UPDATE',
          path: '/' + this.formData.approvalId,
          data: {
            approvalStatus: 'RE_SUBMISSION',
            approvalReason: this.formData.approvalRequestReason,
            requestID: this.formData.agtId,
            requestReason: this.formData.approvalRequestReason,
            fileGroupId: this.formData.attachFileGroupId,
            primaryApproverId: this.formData.primaryApproverId,
            secondaryApproverId: this.formData.secondaryApproverId,
            tertiaryApproverId: this.formData.tertiaryApproverId,
          },
          useErrorPopup: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          let vacationUseData = {
            id: this.formData.id,
            agtId: this.initData.agtId,
            vacationTypeId: this.formData.vacationTypeId,
            vacationTimeTypeCd: this.formData.vacationTimeTypeCd,
            vacationStartYmd: this.formData.vacationStartYmd,
            vacationEndYmd: this.formData.vacationTimeTypeCd === 1234 ? this.formData.vacationStartYmd : this.formData.vacationEndYmd,
            vacationStartTime: this.formData.vacationTimeTypeCd === 1234 ? this.formData.vacationStartTime : null,
            vacationEndTime: this.formData.vacationTimeTypeCd === 1234 ? this.formData.vacationEndTime : null,
            vacationDayoff: this.formData.vacationDayoff,
            vacationDayoffCnt: this.formData.vacationDayoffCnt,
            approvalId: this.formData.approvalId,
            description: this.formData.approvalRequestReason,
          };

          const payload2 = {
            actionname: 'EWM_PERSONAL_VACATION_SAVE',
            data: vacationUseData,
            useErrorPopup: true,
          };

          const res2 = await this.CALL_EWM_API(payload2);

          if (isSuccess(res2)) {
            this.$_Toast(this.$_msgContents('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            this.onCancelFormData();
          }
        }
      },
      onCancelFormData() {
        this.$router.push({ path: '/ewm/personal/vacation' });
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_dept, root_ewm_hr_vacation_division, root_ewm_hr_vacation_time_type');
      },
      /**
       * @description : getDateHolidays() 데이터를 다시 가공한 함수
       * @returns objArr
       */
      // FIXME : EWM 휴일 데이터를 가공 한 메소드, 최우선순위 수정 필요
      getPulicHolidays() {
        // FIXME : yarn 빌드 오류 발생으로 해당 부분 주석처리, 추후에 수정 필요
        // let holiday = new Holidays('KR');
        // let year = new Date().getFullYear();
        // let holidayList = holiday.getHolidays(year);
        // //명절 연휴 당일 전/후 일자 push
        // let longWeekendNameList = ['설날', '추석'];
        // let longWeekendList = [];
        // holidayList.forEach(d => {
        //   if (longWeekendNameList.some(d2 => d2 === d.name)) {
        //     let date = new Date(d.date);
        //     let preDate = formatDate(new Date(date.setDate(date.getDate() - 1)), 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
        //     let preDateObj = { name: d.name, date: preDate };
        //     longWeekendList.push(preDateObj);
        //
        //     date = new Date(d.date);
        //     let nextDate = formatDate(new Date(date.setDate(date.getDate() + 1)), 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
        //     let nextDateObj = { name: d.name, date: nextDate };
        //     longWeekendList.push(nextDateObj);
        //   }
        // });
        // holidayList = [...holidayList, ...longWeekendList]; //push 명절 연휴
        // holidayList = setSortingObjArr(holidayList, { date: 'asc' }); //sort
        return [];
      },
    },
    mounted() {
      this.reqParams = this.$store.getters.getDetailParams;
      this.config.updateYn = this.reqParams ? this.reqParams.updateYn : false;
      this.config.reSubmission = this.reqParams ? this.reqParams.reSubmission : false;
      this.initData = this.reqParams.initData;
      this.initData.leastVacation = this.initData.vacationNumber + this.initData.vacationEditNumber - this.initData.vacationDayoff;
      this.config.myVacation = this.initData.vacationNumber + this.initData.vacationEditNumber - this.initData.vacationDayoff;

      if (this.config.updateYn) {
        this.formData = this.reqParams.formData;
        this.formData.vacationStartTime = formatDate(this.formData.vacationStartTime, 'HHmm', 'HH:mm');
        this.formData.vacationEndTime = formatDate(this.formData.vacationEndTime, 'HHmm', 'HH:mm');
        this.formData.primaryApprovalStatusNm = this.$_enums.ewm.approvalStatus.values.find(
          e => this.formData.primaryApprovalStatus === e.value,
        )?.label;
        this.formData.secondaryApprovalStatusNm = this.$_enums.ewm.approvalStatus.values.find(
          e => this.formData.secondaryApprovalStatus === e.value,
        )?.label;
        this.formData.tertiaryApprovalStatusNm = this.$_enums.ewm.approvalStatus.values.find(
          e => this.formData.tertiaryApprovalStatus === e.value,
        )?.label;
      }

      this.initCodeMap().then(() => {
        this.codes.vacationTypePtCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_vacation_division'], 2);
        this.codes.vacationTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_vacation_division'], 3);
        this.codes.vacationTimeTypeCd.dataSource = this.$_enums.ewm.hrVacationTimeType.values;
        console.log(this.codes.vacationTimeTypeCd.dataSource);

        this.codes.dept.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 4);
        this.initData.depts = this.codes.dept.dataSource.find(d => this.initData.deptCd === d.codeId).path;
      });

      this.vacationTypeList(); //휴가타입리스트 조회
      this.vacationLineList(); //결재라인 조회
      this.getholidays(); //공휴일 체크
    },
  };
</script>
